.contentcontainer {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.yt-cm-mobile-bread-about {
  margin-bottom: 1rem;
}
.item {
  padding-bottom: 30px;
}
.item-details > p {
  margin-bottom: 0px !important;
}
.item-details {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.pageroute {
  color: #264653;
  font-size: 12px;
  line-height: 1.17;
}
.profile-pg-home-about {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: var(--color-gray) !important;
}
.profile-pg-home-about:hover {
  color: var(--color-blue) !important;
}
.about-breadCrum {
  color: var(--color-black) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
}
.cardcontainer {
  padding-top: 40px;
}
.yt-about-us-pg {
  padding-top: 25px;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.yt-main-wrapper {
  padding: 30px;
}
.yt-main-wrapper .item-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 20px;
}
.profile-pg-title {
  color: var(--color-black);
}

.yt-inner-wrap .yt-items.item:first-child {
  padding-top: 0;
}

@media (min-width: 1900px) {
  .yt-main-wrapper {
    padding: 30px;
  }
}
@media (max-width: 1280px) {
  .yt-main-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
}
/*
  * Screen for under 1280 Resolution 
  */
@media (max-width: 1279.92px) {
  .yt-main-wrapper {
    margin: 0px 0px;
    /* padding: 20px 20px; */
  }
  .yt-main-wrapper .item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: normal;
    margin-bottom: 11px;
  }
  .item-details {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
  }
  .lastparagraph {
    margin-bottom: 0px !important;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .yt-inner-wrap .yt-items.item {
    padding-top: 0px !important;
  }
}
/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
  .pageroute {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
  }
  .yt-profile-mb-ttl.profile-pg-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
  }
  .yt-cm-mobile-bread-about {
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .yt-main-wrapper {
    /* margin: 10px 0 10px; */
    padding: 20px;
  }
  .yt-main-wrapper .item-title {
    font-size: 16px;
    line-height: 1.13;
  }
  .item-details {
    line-height: 1.92;
    letter-spacing: 0.12px;
    font-size: 12px;
  }
  .yt-inner-wrap .yt-items.item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-inner-wrap .yt-items.item {
    padding: 30px;
  }
}

.address-body-container {
  width: 1056px;
  height: 766px;
  margin: 20px 120px 173px 40px;
  padding: 169px 387px 168.1px;
  border-radius: 10px;
  background-color: #ffffff;
}

.address-img {
  width: 164.9px;
  height: 216.1px;
  margin: 0 110.1px 53.8px 111px;
  object-fit: contain;
}

.Your-cart-is-empty {
  width: 295px;
  height: 34px;
  margin: 53.8px 51px 17px;
  font-family: GTWalsheimPro;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c213a;
}

.You-havent-added-an {
  width: 383px;
  height: 18px;
  margin: 17px 0 40px;
  font-family: GTWalsheimPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7c8188;
}

.saveAddressBtn {
  width: 200px;
  height: 50px;
  margin: 40px 93px 0;
  padding: 15px 37px;
  opacity: 0.99;
  border-radius: 6px;
  background-color: #3f7a4f;
}

.header-modal {
  text-align: center;
  padding-left: 110px;
  font-weight: bold;
  font-size: xx-large;
}

.saveAddress {
  width: 400px;
  height: 50px;
  margin: 25px 40px 0;
  padding: 15px 37px;
  opacity: 0.99;
  border-radius: 6px;
  background-color: #3f7a4f;
}

.non-empty-Address-box {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

.address-marker {
  width: 20.7px;
  height: 24.7px;
  object-fit: contain;
}

.address-new {
  font-family: GTWalsheimPro;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7c8188;
  width: 100%;
  overflow-x: scroll;
}

.Home {
  width: 52px;
  height: 23px;
  margin: 9px 814px 7px 32px;
  font-family: GTWalsheimPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c213a;
}

.Mask {
  width: 65px;
  height: 65px;
  margin: 0 32px 4px 0;
  padding: 20px 22.3px 20.3px 22px;
  object-fit: contain;
  background-color: #f6f8fa;
}

.selectedAddress {
  width: 29px;
  height: 29px;
  margin: 4px 0 6px 100px;
  object-fit: contain;
}

.editAddress {
  font-family: GTWalsheimPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #30458c;
  cursor: pointer;
}

.deleteAddress {

  font-family: GTWalsheimPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f67054;
  cursor: pointer;
}

.Path-5-Copy-7 {
  width: 0;
  height: 30px;
  margin: 6px 13px 0px 0px;
  border: solid 1px #e2e2e2;
  cursor: pointer;
}

.deleted-a {
  text-align: center;
  cursor: pointer;
}

.delete-modal-header {
  padding-left: 120px;
}

.modalTitleInputLable {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2 !important;
  letter-spacing: normal;
  text-align: left;
  color: #8b8f95;
  margin: 25px 0px 0px 0px;
}

.yt-addAddress-message-show {
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  position: absolute;
  bottom: -20px;
  display: none;
}

.invalid-feedback {
  font-size: 12px;
  line-height: 1.17;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: auto;
  position: absolute;
  top: 0;
  width: 28px;
  right: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
  right: 0px;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
  right: 0px;
}
.checkbox-container {
  margin: 0 auto;
}

.address-data {
  margin-top: 20px !important;
}

.btn{
  padding: 10px;
  border-radius: 5px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
.btn-style-1:hover,
.btn-style-2:hover,
.btn-style-1{
  background: #364F6B;
  color: #fff;
}
.btn-style-2{
  background: #8C8C8C !important;
  color: #fff !important;
}
.btn-style-1 svg{
  width: 19px;
  height: 19px;
}
.addressModal .btn{
  width: 100%;
}
.heading-1{
  gap: 11px;
  align-items: center;
}
.heading-1 img{
  width: 14px;
  height: 20px;
}
.title1{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #262626;
  line-height: 24px;
}
.form-control label{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #757575;
  top: -10px;
}
.form-control input
{
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #DFDFDF !important;
}
.MuiFormLabel-filled,
.form-control label.Mui-focused{
  transform: initial !important;
  color: #757575;
}
.MuiInput-underline:hover:not(.Mui-disabled)::before,
.MuiInput-underline::before,
.MuiInput-underline::after{
  border-bottom: 1px solid #DFDFDF;
}
.btn-groups-tab button{
  min-height: 1px;
  min-width: 29.5%;
  padding-top: 7px;
  border: 1px solid #DFDFDF;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.btn-groups-tab button:not(:last-child){
  border-right: 1px solid #DFDFDF;
}
.btn-groups-tab .MuiTabs-indicator{
  display: none;
}
.modalHeader span svg{
  height: 13px;
  width: 13px;
  font-weight: bolder;
  margin-right: 10px;
}
.btn-groups-tab button.Mui-selected{
  color: #000;
}
.btn-groups-tab button span{
  display: flex !important;
  flex-direction: row !important;
}
.btn-groups-tab button span img
{
  margin: 0px;
  margin-right: 8px;
  opacity: 0.6;
  height: 12px;
}
.addressModal {
  max-width: 410px !important;
}
.btn-groups-tab button span svg{
  width: 13px;
  height: 13px;
  margin-right: 8px;
  margin-bottom: 0px !important;
}
.btn-groups-tab{
  min-height: 1px !important;
}
.MuiTab-labelIcon .MuiTab-wrapper > :first-child{
  margin-bottom: 0px;
}

.input-group .btn {
  width: auto;
  border-color: #ccc;
  display: flex;
  gap: 5px;
  line-height: 24px;
  font-size: 12px;
  text-transform: capitalize;
  padding: 7px 20px;
  align-items: center;
  border-radius: 0px;
}
.input-group 
.input-group-text{
  background-color: transparent;
  border-left-color: #fff;
  font-size: 12px;
  color: #243745;
  line-height: 24px;
  border-radius: 0px;
  cursor: pointer;
}
.input-group input{
  border-right-color: #fff;
  color: #757575;
  line-height: 24px;
  font-size: 12px;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.input-group img{
  height: 13px;
}
.edit-address-title-bar {
  padding: 21px 22.5px;
}
.modalHeader img{
  cursor: pointer;
  margin-right: 13.5px;
  height: 18.6px;
}
.MuiFormHelperText-root.Mui-error,
#location2-helper-text{
  font-size: 10px !important;
  line-height: 12px !important;
}
.btn-groups-tab > div > div{
  flex-wrap: wrap;
}
.addressModal .modalHeader button {
  background: transparent;
  border: 0px;
  padding: 0px;
}
.area_dropdown label{
  font-size: 13px;
}
.my-star-svg {
  color: #ffa500;
}
.my-reviews-page {
  padding: 15px !important;
}
.pp-review-main-content {
  margin-bottom: 8%;
}
/* .pp-review-data {
  padding: 10px;
  max-height: 70vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
} */
.pp-review-data::-webkit-scrollbar {
  width: 2px;
}
.pp-review-data::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 1px;
}
.pp-review-data::-webkit-scrollbar-thumb {
  background-color: var(--color-dark-grey);
}
.pp-review-data::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray);
}
.pp-review-date{
  margin: auto;
  text-align: center;
}

.my-faq-div {
  padding: 40px 40px 36px;
}
.faq-list-content {
  font-family: "GTWalsheimPro-Regular" !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 1.13 !important;
  text-align: left !important;
  color: var(--color-black) !important;
  padding: 10px 0px 10px;
}
.faq-list-ans {
  font-size: 18px;
  font-weight: normal !important;
  color: #737373;
  letter-spacing: 0.8px;
  padding-top: 5px;
  text-align: justify;
}
